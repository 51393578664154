<template>
    <div class="answer">
        <div class="answer__sep" />
        <span class="answer__name">{{ user.name }}</span>
        <span class="answer__comment">{{ comment }}</span>
    </div>
</template>

<script>
export default {
    name: 'BookmakerRatingCommentCardAnswer',

    props: {
        user: {
            type: Object,
            default: () => ({})
        },

        comment: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="stylus" scoped>
    .answer
        display grid
        grid-template-columns min-content 1fr
        grid-template-rows min-content min-content
        grid-gap .5rem

        color $cl-text-dark
        letter-spacing 0

        &__sep
            grid-column 1
            grid-row 1 / 3

            width 2px
            height auto
            background #51A6D4
            border-radius 1px

        &__name
            grid-column 2
            grid-row 1

            font-family $font-family
            font-size 1.4rem
            line-height 1.4rem
            color #51A6D4

        &__comment
            grid-column 2
            grid-row 2

            font-family $font-family-content
            font-size 1rem
            line-height 1rem
            overflow hidden
            white-space nowrap
            text-overflow ellipsis
</style>
