<template>
    <div
        class="predictions-list"
        :class="rootClass"
    >
        <ErrorPlaceholder
            v-if="predictionsListStatus === PAGE_STATUSES.hasError"
            :error="$t('error-text')"
            class="predictions-list__error"
        />

        <div
            v-else-if="isEmpty"
            class="bi-block predictions-list__zero"
        >
            <UiZero v-if="$slots.zero">
                <slot name="zero" />
            </UiZero>
        </div>

        <BiInfiniteList
            v-else
            :load-more="loadMore"
            :is-full="isAutoloadDisabled"
            :class="{ 'predictions-list--full': isAllPredictions }"
        >
            <template v-for="(item, index) in correctPredictions">
                <div
                    :key="getListKey('tips', item.id)"
                    class="predictions-list__item bi-block--list-item"
                >
                    <slot
                        :item="item"
                        :index="index"
                    />
                </div>

                <!-- слот под карусель с рекомендациями -->
                <div
                    v-if="$slots.recommendations && (recommendationsIndex === index)"
                    :key="getListKey('recommendations', item.id)"
                    class="predictions-list__item predictions-list__item--recommendations"
                >
                    <slot name="recommendations" />
                </div>

                <div
                    v-if="index === nativeAdIndex"
                    :key="getListKey('native-add', item.id)"
                    class="predictions-list__item"
                >
                    <DFPPromoPrediction />
                </div>

                <div
                    v-if="interscroller.includes(index + 1)"
                    :key="getListKey('dfp', item.id)"
                    class="predictions-list__item"
                >
                    <core-media :mq="['xxs', 'xs']">
                        <DFPInterscroller
                            overflow
                            placement="interscroller"
                            class="predictions-list__dfp"
                        />
                    </core-media>
                </div>
            </template>
        </BiInfiniteList>
    </div>
</template>

<i18n>
{
    "ru": {
        "error-text": "Что-то пошло не так, прогнозы не загрузились"
    },
    "en": {
        "error-text": "Something went wrong, predictions did not load"
    }
}
</i18n>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DFPInterscroller from '@/components/DFP/DFPInterscroller.vue'
import BiInfiniteList from '@/components/common/bi-infinite-list/index.vue'
import ErrorPlaceholder from '@/components/common/ErrorPlaceholder/ErrorPlaceholder.vue'
import DFPPromoPrediction from '@/components/DFP/DFPPromoPrediction.vue'
import { PAGE_STATUSES } from '@/constants'

export default {
    name: 'PredictionsList',

    components: {
        BiInfiniteList,
        DFPInterscroller,
        ErrorPlaceholder,
        DFPPromoPrediction
    },

    props: {
        /**
         * Тип ленты [pro, smart, my]
         */
        type: {
            type: String,
            default: ''
        },

        autoload: {
            type: Boolean,
            default: false
        },

        filter: {
            type: Object,
            default: () => ({})
        },

        interscroller: {
            type: Array,
            default: () => []
        },

        recommendationsIndex: {
            type: Number,
            default: 0
        },

        nativeAdIndex: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            PAGE_STATUSES,
            isReloading: false
        }
    },

    computed: {
        ...mapGetters('predictions', [
            'isAllPredictions',
            'pageCount',
            'predictions',
            'total'
        ]),

        ...mapState('predictions', ['predictionsListStatus', 'hasNextPage']),

        rootClass () {
            return {
                'is-reload': this.isReloading
            }
        },

        isTypeMy () {
            return this.type === 'my'
        },

        isTypeSmart () {
            return this.type === 'smart' || this.type === 'pro'
        },

        isEmpty () {
            return this.correctPredictions.length === 0
        },

        isAutoloadDisabled () {
            return !this.autoload || !this.hasNextPage
        },

        correctPredictions () {
            return this.predictions.filter(item => !!item.match)
        }
    },

    watch: {
        type () {
            this.resetFilter()
        }
    },

    methods: {
        ...mapActions({
            setFilter: 'predictions/filter'
        }),

        getListKey (prefix, id) {
            return `${prefix}-${id}`
        },

        async loadMore () {
            this.$pivik.event('feed', 'load', '', this.predictions.length)

            await this.loadPredictions({ reset: false })
        },

        async resetFilter () {
            this.isReloading = true

            await this.loadPredictions({ reset: true })

            this.isReloading = false
        },

        loadPredictions ({ reset }) {
            return this.setFilter({
                reset,
                my: this.isTypeMy,
                smart: this.isTypeSmart,
                updates: {
                    ...this.filter
                }
            })
        }
    },

    loadPredictions (store, options) {
        store.dispatch('predictions/setPredictionsListStatus', PAGE_STATUSES.loading)

        return store.dispatch('predictions/filter', options)
            .then((list) => {
                store.dispatch('predictions/setPredictionsListStatus', PAGE_STATUSES.ready)

                return list
            })
            .catch(() => store.dispatch('predictions/setPredictionsListStatus', PAGE_STATUSES.hasError))
    }
}
</script>

<style lang="stylus" scoped>
.predictions-list
    &__item
        max-width 100%
        margin-top .8rem

        &:first-child
            margin-top 0

        &--recommendations
            margin-top 2rem
            margin-bottom 2rem

    &__dfp
        margin 2rem (-($l-column-horizontal-padding-xs))

    &__error,
    &__zero,
    &--full
        margin-bottom 4rem

</style>
