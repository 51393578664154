<template>
    <noindex v-if="recommendations.length">
        <BiPageHeader>
            <h2 class="bi-header-4">
                {{ $t('header') }}
            </h2>
            <template #aside>
                <router-link
                    :to="predictionsRoute"
                    class="link-info"
                    @click.native="sendEvent"
                >
                    {{ $t('btn') }}
                </router-link>
            </template>
        </BiPageHeader>

        <PredictionsRecommendations :items="recommendations" />
    </noindex>
</template>

<i18n>
{
    "ru": {
        "header": "Рекомендуемые прогнозы",
        "btn": "Больше популярных прогнозов"
    },
    "en": {
        "header": "Recommended",
        "btn": "More tips"
    }
}
</i18n>

<script>
import { mapState, mapGetters } from 'vuex'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import PredictionsRecommendations from './PredictionsRecommendations.vue'

export default {
    name: 'PredictionsRecommendationsBlock',

    components: {
        BiPageHeader,
        PredictionsRecommendations
    },

    computed: {
        ...mapGetters('predictions/recommendations', ['recommendations']),
        ...mapState('auth', ['isAuth']),

        predictionsRoute () {
            return {
                name: 'predictions',
                params: {
                    sport: 'football'
                }
            }
        }
    },

    watch: {
        isAuth (newVal) {
            if (newVal) {
                this.reload()
            }
        }
    },

    methods: {
        sendEvent () {
            this.$pivik.event('recommendations', 'all')
        },

        reload () {
            this.$store.dispatch('predictions/recommendations/fetch')
                .catch(() => {})
        }
    }
}
</script>
