<template>
    <I9mLayout>
        <div class="view-imaginarium-prediction">
            <table>
                <tbody>
                    <tr>
                        <td class="stretch view-imaginarium-prediction__title view-imaginarium-prediction__text--large">
                            {{ prediction.user.name }}
                            <span>{{ $t('t-prediction') }}</span>
                        </td>
                        <td class="nowrap view-imaginarium-prediction__logo">
                            <p>
                                <IconLogo />
                                <span>Betting Insider</span>
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="view-imaginarium-prediction__separator view-imaginarium-prediction__dots" />

            <table>
                <tbody>
                    <tr>
                        <td class="nowrap">
                            {{ prediction.match.homeTeam.team.name }} {{ $t('separator') }} {{ prediction.match.awayTeam.team.name }}
                        </td>
                        <td
                            v-if="isWin"
                            class="stretch view-imaginarium-prediction__dots"
                        />
                        <td
                            v-if="isWin"
                            class="t-bold nowrap"
                        >
                            {{ prediction.match.homeTeam.score }} : {{ prediction.match.awayTeam.score }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table class="view-imaginarium-prediction__text--large">
                <tbody>
                    <tr>
                        <td class="nowrap">
                            {{ prediction.fullName }}
                        </td>
                        <td
                            class="stretch
                            view-imaginarium-prediction__dots"
                        />
                        <td class="t-bold nowrap">
                            {{ prediction.rate }}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td class="nowrap">
                            {{ $t('t-rate') }}
                        </td>
                        <td class="stretch view-imaginarium-prediction__dots" />
                        <td class="nowrap">
                            {{ prediction.amount }} <span class="bi-betty" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td class="nowrap">
                            {{ $t('t-amount') }}
                        </td>
                        <td class="stretch view-imaginarium-prediction__dots" />
                        <td class="nowrap">
                            {{ amountWin }} <span class="bi-betty" />
                        </td>
                    </tr>
                </tbody>
            </table>

            <div
                v-if="isWin"
                class="view-imaginarium-prediction__win"
            >
                <span>{{ $t('t-win') }}</span>
            </div>
        </div>

        <div
            slot="footer"
            class="view-imaginarium-prediction__footer"
        >
            {{ $t('t-footer') }}
        </div>
    </I9mLayout>
</template>

<i18n>
{
    "ru": {
        "t-prediction": "сделал прогноз",
        "t-rate": "Ставка",
        "t-amount": "Выигрыш",
        "t-win": "Выплачено",
        "t-footer": "Выигрывай с профи",
        "separator": "—"
    },
    "en": {
        "t-prediction": "made a prediction",
        "t-rate": "Bet",
        "t-amount": "Winnings",
        "t-win": "Paid",
        "t-footer": "Win with the pros",
        "separator": "vs"
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import I9mLayout from '@/layouts/I9m/I9mLayout.vue'

export default {
    name: 'I9mPredictionView',

    components: {
        I9mLayout
    },

    asyncData ({ store, route }) {
        store.dispatch('page/setMountOff')

        return store.dispatch('predictions/prediction/fetch', { id: route.params.id })
    },

    computed: {
        ...mapGetters('predictions/prediction', ['prediction']),

        isWin () {
            return this.prediction.state === 'WIN'
        },

        amountWin () {
            if (this.isWin) {
                return this.prediction.amountWin
            }

            return (this.prediction.amount * this.prediction.rate).toFixed(2)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .view-imaginarium-prediction
        padding-left 25px
        padding-right 25px
        color $cl-text-dark
        font-size 3.8rem
        line-height 1

        &__text--large
            font-weight $font-weight-bold
            font-size 5.6rem

        &__row
            display flex
            margin-bottom 20px
            white-space nowrap

        table
            margin-bottom 20px
            width 100%

            td p
                margin 0

            td.stretch
                width 100%

            td.nowrap
                white-space nowrap

        &__separator
            margin-bottom 40px
            padding-bottom 15px

        &__footer
            text-transform uppercase

        &__title
            flex 1

            span
                display block
                font-size 3.8rem
                font-weight $font-weight-regular

        &__dots
            flex 1 1
            background-image linear-gradient(to right, $cl-secondary 33%, #fff 0%)
            background-position 0 calc(100% - 5px)
            background-size 6px 2px
            background-repeat repeat-x

        &__logo
            margin-top 20px
            line-height 60px
            color $cl-primary
            font-weight $font-weight-bold
            text-transform uppercase
            font-size 3.5rem

            .ui-icon
                display inline-block
                margin 0 20px
                width 55px
                height 55px

            span
                vertical-align top

        &__win
            position absolute
            bottom 17%
            right 7%
            min-width 370px
            height 110px
            line-height 110px
            text-align center
            font-size 5rem
            letter-spacing 4px
            font-weight $font-weight-bold
            text-transform uppercase
            transform rotate(-15deg)
            background-repeat no-repeat
            background-size 100% 110px
            background-image url('@/assets/images/imaginarium/frame_green.png')

            span
                -webkit-background-clip text
                -webkit-text-fill-color transparent
                background-image url('@/assets/images/imaginarium/gb_green_white.png')
</style>
