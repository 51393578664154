<template>
    <I9mLayout footer-site-name>
        <div class="view-imaginarium-profile">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <UiAvatar
                                :src="user.avatar"
                                class="view-imaginarium-profile__avatar"
                            />
                        </td>

                        <td class="stretch">
                            <span class="view-imaginarium-profile__name t-bold">{{ user.name }}</span>
                            <span class="view-imaginarium-profile__roi"><span :class="roiClass">{{ user.roi }}</span> ROI {{ $t('t-roi') }}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="view-imaginarium-profile__sep" />

            <table>
                <tbody>
                    <tr>
                        <td>
                            <div class="view-imaginarium-profile__stat" />
                        </td>
                        <td class="stretch">
                            <table>
                                <tbody>
                                    <tr>
                                        <td class="nowrap">
                                            <table class="view-imaginarium-profile__values">
                                                <tbody>
                                                    <tr>
                                                        <td><span class="view-imaginarium-profile__state green" /></td>
                                                        <td><span class="t-bold">{{ wins }}%</span></td>
                                                        <td class="stretch">
                                                            {{ $t('t-wins') }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="view-imaginarium-profile__state red" /></td>
                                                        <td><span class="t-bold">{{ lose }}%</span></td>
                                                        <td class="stretch">
                                                            {{ $t('t-lose') }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><span class="view-imaginarium-profile__state" /></td>
                                                        <td><span class="t-bold">{{ cancel }}%</span></td>
                                                        <td class="stretch">
                                                            {{ $t('t-cancel') }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>

                                        <td />

                                        <td class="t-center view-imaginarium-profile__col-place">
                                            <span class="view-imaginarium-profile__place">{{ user.rank }}</span>
                                            <!-- eslint-disable-next-line vue/no-v-html -->
                                            <span v-html="$t('t-place')" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div
            slot="footer"
            class="view-imaginarium-profile__footer"
        >
            {{ $t('t-footer') }}
        </div>
    </I9mLayout>
</template>

<i18n>
{
    "ru": {
        "t-wins": "выиграл",
        "t-lose": "проиграл",
        "t-cancel": "отмены",
        "t-place": "место<br/> за все время",
        "t-roi": "за все время",
        "t-footer": "Выигрывай с профи"
    },
    "en": {
        "t-wins": "won",
        "t-lose": "lost",
        "t-cancel": "cancelled",
        "t-place": "place<br/> all-time",
        "t-roi": "all-time",
        "t-footer": "Win with the pros"
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import I9mLayout from '@/layouts/I9m/I9mLayout.vue'

export default {
    name: 'I9mProfileView',

    components: {
        I9mLayout
    },

    asyncData ({ store, route }) {
        store.dispatch('page/setMountOff')

        return Promise.all([
            store.dispatch('profile/fetchProfile', { id: route.params.id }),
            store.dispatch('profile/fetchUserStatistics', { id: route.params.id })
        ])
    },

    computed: {
        ...mapGetters({
            getUserInfo: 'profile/item',
            getUserStatistics: 'profile/stat'
        }),

        userId () {
            return this.$route.params.id
        },

        user () {
            return this.getUserInfo({ id: this.userId })
        },

        stat () {
            return this.getUserStatistics({ id: this.userId })
        },

        roiClass () {
            if (this.user.roi > 0) {
                return 't-color-accent'
            }

            if (this.user.roi < 0) {
                return 't-color-warn'
            }

            return 't-color-secondary'
        },

        wins () {
            return Math.round((this.stat.bets_count.win * 100) / this.stat.bets_count.total) || 0
        },

        lose () {
            return Math.round((this.stat.bets_count.lose * 100) / this.stat.bets_count.total) || 0
        },

        cancel () {
            return Math.round((this.stat.bets_count.return * 100) / this.stat.bets_count.total) || 0
        }
    }
}
</script>

<style lang="stylus" scoped>
    .view-imaginarium-profile
        padding-left 5px
        padding-right 5px
        color $cl-text-dark
        font-size 3.8rem
        line-height 1

        table
            width 100%

            td p
                margin 0

            td.stretch
                width 100%

            td.nowrap
                white-space nowrap

            td.t-center
                text-align center

        &__avatar
            margin-right 45px
            display block

            &.ui-avatar
                width 180px
                height @width

        &__state
            display inline-block
            width 28px
            height @width
            border-radius 50%
            background-color $cl-secondary
            margin-right 25px

            &.green
                background-color $cl-success-light

            &.red
                background-color $cl-error

        &__stat
            margin-right 45px
            width 180px
            height @width

        &__name
            margin-bottom 20px
            display block
            font-size 5.6rem

        &__roi
            span:first-child
                font-size 5.6rem
                font-weight $font-weight-bold

        &__sep
            margin-bottom 20px
            margin-left 225px
            padding-bottom 15px
            background-image linear-gradient(to right, $cl-secondary 33%, #fff 0%)
            background-size 6px 2px
            background-repeat repeat-x
            background-position bottom


        &__values
            td
                padding 15px 0

                &:nth-child(2)
                    padding-right 20px

        &__col-place
            width 180px

        &__place
            margin-bottom 30px
            display block
            font-size 5.6rem
            font-weight $font-weight-bold

        &__footer
            text-transform uppercase
</style>
