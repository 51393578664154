<template>
    <div>
        <UiBreadcrumbs
            :items="breadcrumbs"
            class="view-predictions-item__breadcrumbs"
        />

        <BiPageHeader>
            <h1 class="bi-header-4">
                {{ titleText }}
            </h1>
        </BiPageHeader>

        <PredictionsItem
            expand
            :prediction="prediction"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "header": "Прогноз на матч",
        "breadcrumbs": "Прогнозы на %{sportName}",
        "breadcrumbs-prediction": "Прогноз %{author} %{predictionId}",
        "title": "%{matchName}: %{date} - прогноз на матч %{predictionName} от %{author}",
        "description": "Прогноз на матч %{matchName} %{date} от %{author}: предматчевая аналитика и статистика, прошлые матчи команд",
        "h1": "Матч %{matchName} - прогноз от %{author} %{date}"
    },
    "en": {
    }
}
</i18n>

<script>
import _get from 'lodash.get'
import { mapGetters } from 'vuex'
import PredictionsItem from '@/components/Predictions/Item/PredictionsItem.vue'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import { IS_BETTING_COM } from '@/constants'
import isMatchPageAvailable from '@/utils/is-match-page-available'
import meta from '@/views/predictions/item/meta'

export default {
    name: 'PredictionsItemView',

    components: {
        PredictionsItem,
        BiPageHeader
    },

    mixins: [meta],

    async asyncData ({ store, route }) {
        if (IS_BETTING_COM) {
            return Promise.reject({ code: 404 })
        }

        const predictionId = route.params.id
        const sportId = route.params.sport

        try {
            const [sports] = await Promise.all([
                store.dispatch('newSports/fetchSportsIfEmpty'),
                store.dispatch('predictions/prediction/fetchNewSport', { id: predictionId })
            ])

            if (!sports.find(sport => sport.id === sportId)) {
                return Promise.reject({ code: 404 })
            }

            store.dispatch('newSports/setSelectedSportId', sportId)
        } catch (err) {
            if (err.code === 404 || (err.code === 301 && err.route)) {
                return Promise.reject(err)
            }
        }
    },

    computed: {
        ...mapGetters('predictions/prediction', ['prediction']),
        ...mapGetters('newSports', ['selectedSport']),

        date () {
            return this.$date.transform(this.prediction.ctime * 1000, 'date')
        },

        breadcrumbs () {
            return [
                {
                    title: 'Betting Insider',
                    to: {
                        name: 'home'
                    }
                },
                {
                    title: this.$t('breadcrumbs', { sportName: this.selectedSport.name }),
                    to: {
                        name: 'predictions-new-sports',
                        params: {
                            sport: this.prediction.match.sport.code
                        }
                    }
                },
                {
                    title: this.matchName,
                    to: this.matchRoute
                },
                {
                    title: this.breadcrumbsPrediction
                }
            ]
        },

        titleText () {
            return this.$t('h1', {
                matchName: this.matchName,
                author: this.predictionAuthor,
                date: this.date
            })
        },

        image () {
            const { locale } = this.$i18n
            const predictionId = this.$route.params.id

            return `https://betting.team/imaginarium/images/bettinginsider/${locale}/prediction/${predictionId}.jpeg`
        },

        matchName () {
            const homeTeamName = _get(this.prediction, 'match.homeTeam.team.name', '')
            const awayTeamName = _get(this.prediction, 'match.awayTeam.team.name', '')

            return `${homeTeamName} — ${awayTeamName}`
        },

        matchRoute () {
            if (isMatchPageAvailable(this.prediction.match)) {
                return {
                    name: 'match-new-sports',
                    params: {
                        sport: this.prediction.match.sport.code,
                        id: this.prediction.match.id
                    }
                }
            }

            return null
        },

        predictionAuthor () {
            return _get(this.prediction, 'user.name', '')
        },

        tournament () {
            return _get(this.prediction, 'match.tournament', '')
        },

        breadcrumbsPrediction () {
            return this.$t('breadcrumbs-prediction', {
                matchName: this.matchName,
                author: this.predictionAuthor
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.view-predictions-item
    &__breadcrumbs
        margin-bottom .8rem

        +layout-sm-and-up()
            margin-bottom 1.6rem

</style>
