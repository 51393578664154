<template>
    <div
        :style="{ backgroundColor: primaryColor }"
        class="bookmaker-header"
    >
        <div class="bookmaker-header-main">
            <div class="bookmaker-header-content">
                <div class="bookmaker-header__up">
                    <div class="bookmaker-header__logo">
                        <img
                            v-if="logo"
                            :src="logo"
                            :alt="name"
                        >
                        <div
                            v-else
                            class="bookmaker-header__logo-default"
                        />
                    </div>

                    <div class="bookmaker-header__name">
                        {{ name }}
                    </div>
                </div>

                <div class="bookmaker-header__wrapper">
                    <div class="bookmaker-header__rating">
                        <div class="bookmaker-header__rating-text">
                            {{ $t('score_bi') }} : {{ averageScore }}
                        </div>

                        <UiStarRating
                            read-only
                            :rating="scoreAvg"
                            :star-size="16"
                            :increment="0.1"
                            :padding="5"
                            active-color="#fff"
                            inactive-color="#BAC3C6"
                        />
                    </div>

                    <div
                        v-if="isMobileApp"
                        class="bookmaker-header__sep"
                    />

                    <div>
                        <div
                            v-if="isMobileApp"
                            class="bookmaker-header__applications"
                        >
                            <div>{{ $t('applications') }}</div>

                            <div class="bookmaker-header__apps">
                                <a
                                    v-if="androidLink"
                                    :href="androidLink"
                                    rel="nofollow"
                                    @click="sendEventApp('android')"
                                >
                                    <IconAndroid />
                                </a>

                                <a
                                    v-if="iosLink"
                                    :href="iosLink"
                                    rel="nofollow"
                                    @click="sendEventApp('iOS')"
                                >
                                    <IconApple />
                                </a>
                            </div>
                        </div>

                        <div class="bookmaker-header__name bookmaker-header__name--hide-sm">
                            {{ name }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="bookmaker-header__description">
                {{ description }}
            </div>
        </div>

        <div class="bookmaker-header-aside">
            <a
                v-if="link"
                :href="link"
                rel="nofollow"
                class="bookmaker-header__link"
                @click="sendEventSite"
            >
                {{ $t('link_btn') }}
            </a>

            <div
                v-if="isMobileApp"
                class="bookmaker-header__applications bookmaker-header__applications--hide-sm"
            >
                <span>{{ $t('applications') }}</span>

                <a
                    v-if="androidLink"
                    :href="androidLink"
                    rel="nofollow"
                    @click="sendEventApp('android')"
                >
                    <IconAndroid />
                </a>

                <a
                    v-if="iosLink"
                    :href="iosLink"
                    rel="nofollow"
                    @click="sendEventApp('iOS')"
                >
                    <IconApple />
                </a>
            </div>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "score_bi": "Оценка BI",
        "applications": "Приложения",
        "link_btn": "Перейти на сайт"
    },
    "en": {
        "score_bi": "Rating BI",
        "applications": "Mobile apps",
        "link_btn": "Site"
    }
}
</i18n>

<script>
import IconApple from '@/assets/svg/apple.icon.svg'
import IconAndroid from '@/assets/svg/android.icon.svg'
import isValidHex from '@/utils/hex'

const DEFAULT_BG_COLOR = '#1F1F1F'

export default {
    name: 'BookmakersRatingPageHeader',

    components: {
        IconApple,
        IconAndroid
    },

    props: {
        webname: {
            type: String,
            default: ''
        },

        /**
         * Имя букмекера
         */
        name: {
            type: String,
            default: ''
        },

        logo: {
            type: String,
            default: ''
        },

        /**
         * Цвет фона
         */
        color: {
            type: String,
            default: ''
        },

        websites: {
            type: Array,
            default: () => []
        },

        description: {
            type: String,
            default: ''
        },

        scoreAvg: {
            type: Number,
            default: 0
        },

        iosLink: {
            type: String,
            default: ''
        },

        androidLink: {
            type: String,
            default: ''
        }
    },

    computed: {
        averageScore () {
            const val = this.scoreAvg
            const fixStr = Number.isNaN(val) ? 0 : parseFloat(val)

            return fixStr.toFixed(1)
        },

        link () {
            const site = this.websites.find(item => item.type === 'MAIN')

            if (site) {
                return site.url
            }

            return null
        },

        primaryColor () {
            return isValidHex(this.color) ? this.color : DEFAULT_BG_COLOR
        },

        isMobileApp () {
            return this.androidLink || this.iosLink
        }
    },

    methods: {
        sendEventSite () {
            this.$emit('event-site')
        },

        sendEventApp (app) {
            this.$emit('event-app', app)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .bookmaker-header
        padding 1.6rem
        display flex
        flex-direction row
        border-radius $radius-block
        background-image linear-gradient(90deg, rgba(255, 255, 255, .09) 0%, rgba(0, 0, 0, .1) 57%)
        color #fff
        user-select none

        +layout-sm()
            padding 2rem
            flex-direction column

        +layout-xxs()
            border-radius initial
            padding 2rem 1.2rem

        &-main
            flex 1
            display flex
            flex-direction column

        &-content
            display flex
            flex-direction row

            +layout-sm()
                flex-direction column

        &__wrapper
            display flex
            flex-direction row
            align-items center

            +layout-sm()
                margin-top 1.5rem

            +layout-xs()
                justify-content center

        &__sep
            width 1px
            height 3.6rem
            background rgba($cl-secondary-light, .18)

            +layout-sm()
                margin-right 2rem

        &__up
            display flex
            flex-direction row
            align-items center

            +layout-xs()
                flex-direction column

        &__logo
            width 14rem
            height 5rem

            img
                width 100%
                height 100%
                object-fit cover

            &-default
                width 100%
                height @width
                border-radius 6px
                background-color rgba(@color, .09)
                background-image url('../../assets/images/photo.svg')
                background-repeat no-repeat
                background-position center

        &__name
            display none
            padding 0 2rem
            text-transform uppercase
            font-family $font-family
            font-size 2.4rem

            +layout-sm()
                display block

            &--hide-sm
                display block

                +layout-sm()
                    display none

        &__rating
            padding 0 2rem
            font-family $font-family-content
            font-size 1.2rem

            &-text
                margin-bottom .7rem
                color rgba(@color, .4)

        &__description
            margin-top 1.5rem
            line-height 1.8rem
            font-family $font-family-content
            font-size 1.2rem
            color rgba(@color, .7)

            +layout-xs()
                text-align center

        &-aside
            display flex
            flex-direction column
            align-items flex-end
            justify-content space-between

            +layout-sm()
                margin-top 2rem

        &__applications
            display none
            align-items center
            color rgba(@color, .4)
            font-family $font-family-content
            font-size 1.2rem

            +layout-sm()
                display flex
                flex-direction column

            &--hide-sm
                display flex

                +layout-sm()
                    display none

            a
                display flex
                margin-left 1.5rem
                color rgba(@color, .4)

            svg
                width auto
                height 1.8rem

        &__apps
            display flex
            margin-top .7rem

            +layout-sm()
                justify-content center

            a
                margin-left 0
                color #fff

                &:not(:last-child)
                    margin-right 1.5rem

        &__link
            button(5, 'contained', 'white')

            +layout-sm()
                width 100%
</style>
