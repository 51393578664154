class InfiniteScroll {
    /**
     *
     * @param element
     * @param options
     * @param {Function} options.callback
     * @param {Function} options.callback
     */
    constructor (element, options) {
        this.element = element
        this.options = options

        this.infiniteScroll_throttleTimeout = false
        this.infiniteScroll_event_onScroll = this.onScroll.bind(this)

        document.addEventListener('scroll', this.infiniteScroll_event_onScroll)
    }

    checkVisible () {
        const rect = this.element.getBoundingClientRect()
        const offset = rect.bottom - window.innerHeight - this.options.distance

        if (offset <= 0) {
            if (typeof this.options.callbackLoad === 'function') {
                this.options.callbackLoad()
            }
        }
    }

    onScroll () {
        // Если запущен `throttle` таймаут или заблокированное состояние,
        // то отменяем обработку
        if (this.infiniteScroll_throttleTimeout || this.options.isDisabled()) {
            return
        }

        this.infiniteScroll_throttleTimeout = setTimeout(() => {
            clearTimeout(this.infiniteScroll_throttleTimeout)
            this.infiniteScroll_throttleTimeout = false
            this.checkVisible()
        }, this.options.throttle)
    }

    destroy () {
        document.removeEventListener('scroll', this.infiniteScroll_event_onScroll)
    }
}


export default {
    /**
     * @param {Element} element
     * @param {Object} binding
     * @param {VueNode} vnode
     */
    bind (element, binding, vnode) {
        element.custom_infiniteScroll = new InfiniteScroll(element, {
            /**
             * @Attr infinite-scroll-distance
             * Дистанция от низа блока для срабатывания подгрузки
             */
            distance: parseInt(vnode.data.attrs['infinite-scroll-distance']) || 0,

            /**
             * @param {Number}
             * Задержка срабатывания проверки (в ms)
             */
            throttle: parseInt(vnode.data.attrs['infinite-scroll-throttle']) || 200,

            isDisabled () {
                /**
                 * @Attr infinite-scroll-disabled
                 * Ключ переменной в которой храниться состояние блокировки.
                 */
                const key = vnode.data.attrs['infinite-scroll-disabled']

                return vnode.context[key]
            },

            callbackLoad: binding.value
        })
    },

    unbind (element) {
        if (element.custom_infiniteScroll) {
            element.custom_infiniteScroll.destroy()
        }
    }
}
