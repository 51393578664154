import { render, staticRenderFns } from "./BookmakerRatingCommentCardAnswer.vue?vue&type=template&id=873a0626&scoped=true&"
import script from "./BookmakerRatingCommentCardAnswer.vue?vue&type=script&lang=js&"
export * from "./BookmakerRatingCommentCardAnswer.vue?vue&type=script&lang=js&"
import style0 from "./BookmakerRatingCommentCardAnswer.vue?vue&type=style&index=0&id=873a0626&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "873a0626",
  null
  
)

export default component.exports