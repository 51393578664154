<template>
    <div class="review-header">
        <div class="review-header__user">
            <div
                v-if="isMy"
                class="review-header__badge"
            >
                <IconProfile />
                <span>{{ $t('my-review') }}</span>
            </div>

            <router-link
                :to="userProfileRoute"
                class="review-header__username link-dark-inner"
            >
                {{ user.name }}
            </router-link>
        </div>

        <div class="review-header__stat">
            <ItemShape
                v-if="userBets"
                :bets="userBets"
            />

            <span>{{ user.winRate }}% WR</span>
            <span>{{ user.roi }}% ROI</span>
        </div>

        <UiDate
            :timestamp="ctime"
            live
            format="akhmatova"
            class="review-header__date"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "my-review": "Мой отзыв"
    },
    "en": {
        "my-review": "My review"
    }
}
</i18n>

<script>
import ItemShape from '@/components/common/bi-shape/index.vue'
import IconProfile from '@/assets/svg/profile.icon.svg'

export default {
    name: 'BookmakersRatingReviewCardHeader',

    components: {
        ItemShape,
        IconProfile
    },

    props: {
        isMy: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        ctime: {
            type: Number,
            default: 0
        },

        isEdit: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        userProfileRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.user.id
                }
            }
        },

        userBets () {
            return (this.user.betsForm || []).length ? this.user.betsForm : null
        }
    }
}
</script>

<style lang="stylus" scoped>
    .review-header
        padding 1rem 2rem
        display grid
        grid-template-columns auto min-content 1fr
        grid-gap 1rem

        align-items center
        border-bottom 1px solid $cl-secondary-light
        font-family $font-family-content
        font-weight $font-weight-medium
        font-size 1.2rem
        user-select none

        +layout-xs()
            grid-template-columns 1fr min-content
            grid-template-rows 1fr
            grid-gap .5rem

        &__user
            display flex
            align-items center

        &__badge
            margin-right .5rem
            padding .4rem 1rem
            display flex
            align-items center
            border-radius 15px
            background #ECECED
            text-transform uppercase

            +layout-xs()
                padding .6rem
                border-radius 50%

            & > span
                margin-left .5rem

                &:not(:last-child):after
                    content "|"
                    margin 0 .5rem

                +layout-xs()
                    display none

        &__username
            color $cl-text-dark
            overflow hidden
            text-overflow ellipsis

        &__stat
            display flex
            align-items center
            color $cl-secondary
            white-space nowrap
            overflow hidden

            +layout-xs()
                grid-row 2
                grid-column 1 / 3

            & > *:not(:last-child):after
                content "|"
                margin 0 .8rem
                color $cl-secondary-light

        &__date
            text-align right
            white-space nowrap
            color #DADDDE
</style>
