<template>
    <div class="bi-infinite-list">
        <transition-group
            v-infinite-scroll="autoLoad"
            name="animation-list-bottom"
            tag="div"
            infinite-scroll-disabled="autoLoadDisabled"
            infinite-scroll-distance="500"
        >
            <slot />
        </transition-group>

        <template v-if="!isFull">
            <div
                v-if="lightTheme"
                class="bi-infinite-list__btn bi-infinite-list__btn--light link-info-inner bi-button"
                @click="manualLoad"
            >
                <span>{{ placeholder }}</span>
            </div>

            <button
                v-else
                :pending="pending"
                class="bi-infinite-list__btn bi-infinite-list__btn-load bi-infinite-list__btn-load--stretch"
                @click="manualLoad"
            >
                {{ placeholder }}
            </button>
        </template>
    </div>
</template>

<i18n>
{
    "ru": {
        "more": "Еще"
    },
    "en": {
        "more": "More"
    }
}
</i18n>

<script>
import infiniteScroll from '@/directives/infinite-scroll'


export default {
    name: 'BiInfiniteList',

    directives: {
        infiniteScroll
    },

    props: {
        buttonText: {
            type: String,
            default: ''
        },

        lightTheme: {
            type: Boolean,
            default: false
        },

        /**
         * Состояние, означает что список заполнен.
         * Скрывает кнопку еще и блокирует событие загрузки
         */
        isFull: {
            type: Boolean,
            default: false
        },

        /**
         * Количество автозагрузок
         */
        autoLoadCount: {
            type: Number,
            default: 3
        },

        /**
         * Функция подгрузки контента
         * Возвращайте промис для автоматической смены состояния
         */
        loadMore: {
            type: Function,
            default: null
        }
    },

    data () {
        return {
            // Максимальное количество автозагрузок
            autoLoadMaxCount: 3,
            // Количество повторных автозагрузок
            loadCount: 0,
            // Состояние для автозагрузки
            autoLoadDisabled: false,
            pending: false
        }
    },

    computed: {
        placeholder () {
            return this.buttonText || this.$t('more')
        }
    },

    watch: {
        isFull (val) {
            this.autoLoadDisabled = val
        },

        autoLoadCount (val) {
            this.autoLoadMaxCount = val
        }
    },

    mounted () {
        this.autoLoadDisabled = this.isFull
        this.autoLoadMaxCount = this.autoLoadCount
    },

    methods: {
        /**
         * Метод для авто загрузки
         */
        autoLoad () {
            if (this.loadCount === this.autoLoadMaxCount || this.autoLoadDisabled) {
                this.autoLoadDisabled = true

                return
            }

            this.loadCount += 1

            if (!this.pending) {
                this.load()
            }
        },

        /**
         * Метод для ручной загрузки
         */
        manualLoad () {
            this.loadCount = 0

            if (!this.pending) {
                this.load()
            }
        },

        /**
         * Метод загрузки.
         * Устанавливает состояние в заблокирован и загрузка,
         * вызывает метод loadMore.
         * Если метод loadMore возвращает промис,
         * то по окончанию загрузки состояние бедет меняться автоматически
         */
        load () {
            if (typeof this.loadMore === 'function') {
                this.autoLoadDisabled = true
                this.pending = true

                const result = this.loadMore()

                // Если в ответ возвращается промис,
                // то состояние поменяется автоматически
                if (result.then && result.catch) {
                    return result
                        .then(() => {
                            this.autoLoadDisabled = false
                            this.pending = false
                        })
                        .catch(() => {
                            this.autoLoadDisabled = true
                            this.pending = false
                        })
                }
            }

            // eslint-disable-next-line no-console
            console.error('this.loadMore is not a function')

            return null
        }
    }
}
</script>

<style lang="stylus">
    .bi-infinite-list__btn
        margin-top 1rem

        +layout-md()
            margin-top .8rem

        &--light
            line-height 2.1rem
            overflow hidden
            text-align center
            cursor pointer

            &:before, &:after
                content ''
                position relative
                display inline-block
                width 100%
                height 2px
                z-index 1
                background $cl-secondary-light
                vertical-align middle

            &:before
                right 2rem
                margin 0 0 0 -100%

            &:after
                left 2rem
                margin 0 -100% 0 0

        &-load
            button(5, 'contained', 'text')

</style>
