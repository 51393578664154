<template>
    <div class="error">
        <WarningSignIcon class="error__warning-sign" />

        <div
            class="error__text"
            v-html="error"
        />

        <button
            class="error__refresh-button"
            @click="refreshPage"
        >
            {{ $t('refresh-page') }}
        </button>
    </div>
</template>

<i18n>
{
    "ru": {
        "refresh-page": "Перезагрузить страницу"
    },
    "en": {
        "refresh-page": "Refresh page"
    }
}
</i18n>

<script>
import WarningSignIcon from '@/assets/svg/warning-sign.icon.svg'

export default {
    name: 'ErrorPlaceholder',

    components: {
        WarningSignIcon
    },

    props: {
        error: {
            type: String,
            default: ''
        }
    },

    methods: {
        refreshPage () {
            window.location.reload()
        }
    }
}
</script>

<style lang="stylus" scoped>
.error
    width 100%
    height 100%
    display flex
    flex-direction column
    justify-content center
    align-items center

    &__warning-sign
        margin-bottom 1rem

    &__text
        margin-bottom 2.4rem
        font-size: 1.8rem
        line-height 2.4rem
        letter-spacing .015rem
        text-align center

    &__refresh-button
        button(5, 'contained', 'primary')

</style>
