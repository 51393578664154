<template>
    <div class="form">
        <textarea
            ref="textarea"
            v-autogrow
            :value="value"
            :placeholder="placeholder"
            class="form__textarea"
            @input="onInput"
            @keydown="$emit('keydown', $event)"
        />

        <div
            v-if="error"
            class="form__textarea-error bi-subtitle-1"
        >
            {{ error }}
        </div>

        <button
            :disabled="!value"
            :pending="isButtonPending"
            class="form__btn"
            @click="$emit('submit')"
        >
            <IconSend />
        </button>
    </div>
</template>

<script>
import { TextareaAutogrowDirective } from '@/directives/autogrow'
import IconSend from '@/assets/svg/send.icon.svg'

export default {
    name: 'SendTextForm',

    components: {
        IconSend
    },

    directives: {
        autogrow: TextareaAutogrowDirective
    },

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: String,
            required: true
        },

        placeholder: {
            type: String,
            default: ''
        },

        error: {
            type: String,
            default: ''
        },

        isButtonPending: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        onInput (event) {
            this.$emit('input', event.target.value)
        },

        focus () {
            this.$refs.textarea.focus()
        }
    }
}
</script>

<style lang="stylus" scoped>
.form
    position relative

    &__textarea
        input()
        overflow hidden
        resize none

        &:not(input)
            padding-right 3.2rem

        &-error
            margin-top .8rem
            color $cl-error

    &__btn
        button(5, 'icon', 'primary')
        position absolute
        top .8rem
        right .8rem

        &[disabled]
            display none

</style>
