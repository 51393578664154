<template>
    <div
        :class="{ 'is-edit': text }"
        class="comment-form"
    >
        <SendTextForm
            :value="text"
            :placeholder="placeholder"
            @input="filter"
            @submit="submit"
        />

        <div
            v-if="quote.userName"
            class="comment-form__reply"
        >
            <IconArrowReply />
            <span class="comment-form__user-name">{{ quote.userName }}</span>
            <span class="comment-form__reply-comment">{{ quote.comment }}</span>
        </div>
    </div>
</template>

<script>
import SendTextForm from '@/components/common/SendTextForm/SendTextForm.vue'
import IconArrowReply from '@/assets/svg/arrow-reply.icon.svg'
import lineBreak from '@/filters/line-break'

export default {
    name: 'BookmakerRatingCommentForm',

    filters: {
        lineBreak
    },

    components: {
        SendTextForm,
        IconArrowReply
    },

    props: {
        comment: {
            type: String,
            default: ''
        },

        placeholder: {
            type: String,
            default: ''
        },

        quote: {
            type: Object,
            default: () => ({})
        }
    },

    data () {
        return {
            text: ''
        }
    },

    watch: {
        comment () {
            this.text = this.comment
        }
    },

    methods: {
        submit () {
            this.$emit('submit', this.text)
        },

        filter (value) {
            this.text = lineBreak(value)
        }
    }
}
</script>

<style lang="stylus" scoped>
.comment-form
    &__reply
        margin .8rem 0
        display flex
        align-items center
        white-space nowrap
        font-size 1.4rem
        line-height 1.8rem
        color $cl-secondary

    &__user-name
        margin-left .9rem
        color $cl-text-dark

        +layout-xs()
            margin-left .6rem

    &__reply-comment
        margin 0 .9rem
        flex 1
        overflow hidden
        text-overflow ellipsis

        +layout-xs()
            margin 0 .6rem
</style>
