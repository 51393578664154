<template>
    <div class="comment bi-block">
        <router-link
            :to="userRoute"
            class="comment__avatar"
        >
            <UiAvatar
                size="sm"
                :src="user.avatar"
            />
        </router-link>

        <div class="comment__user-info">
            <router-link
                :to="userRoute"
                class="comment__user-name link-dark-inner"
            >
                {{ user.name }}
            </router-link>

            <div class="comment__info">
                <BiShape
                    v-if="userBets"
                    :bets="userBets"
                    class="comment__user-stat"
                />

                <span class="comment__user-stat">{{ user.winRate }}% WR</span>
                <span class="comment__user-stat">{{ user.roi }}% ROI</span>
            </div>
        </div>

        <div class="comment__content">
            <BookmakerRatingCommentCardAnswer
                v-if="parentComment"
                :user="parentComment.user"
                :comment="parentComment.text"
                class="comment__answer"
            />

            <pre class="comment__comment">{{ comment }}</pre>
        </div>

        <UiDate
            :timestamp="date"
            live
            format="akhmatova"
            class="comment__right-up comment__date"
        />

        <div class="comment__right-down">
            <button
                class="comment__reply"
                :disabled="disableReply"
                @click="reply"
            >
                {{ $t('reply') }}
            </button>

            <ReportButton
                v-if="!isMy"
                :id="commentId"
                object-type="COMMENT"
            />
        </div>

        <span
            v-if="isAdmin"
            class="comment__remove"
            @click="$emit('remove', commentId)"
        >
            <IconClose />
        </span>
    </div>
</template>

<i18n>
{
    "ru": {
        "reply": "Ответить"
    },
    "en": {
        "reply": "Reply"
    }
}
</i18n>

<script>
import { mapGetters } from 'vuex'
import BiShape from '@/components/common/bi-shape/index.vue'
import BookmakerRatingCommentCardAnswer from '@/components/BookmakersRating/Comments/BookmakerRatingCommentCardAnswer.vue'
import ReportButton from '@/components/common/ReportButton/ReportButton.vue'

export default {
    name: 'BookmakerRatingCommentCard',

    components: {
        BookmakerRatingCommentCardAnswer,
        BiShape,
        ReportButton
    },

    props: {
        isMy: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        commentId: {
            type: String,
            default: ''
        },

        date: {
            type: Number,
            default: 0
        },

        comment: {
            type: String,
            default: ''
        },

        parentComment: {
            type: Object,
            default: () => ({})
        },

        disableReply: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters('profile', ['isAdmin']),

        userRoute () {
            return {
                name: 'profile',
                params: {
                    id: this.user.id
                }
            }
        },

        userBets () {
            return (this.user.betsForm || []).length ? this.user.betsForm : null
        }
    },

    methods: {
        reply () {
            this.$emit('reply', this.commentId)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .comment
        position relative
        padding 1.2rem
        display grid
        grid-template-columns min-content minmax(3rem, 1fr) min-content
        grid-template-rows min-content min-content min-content
        grid-gap .6rem 2rem
        font-size $font-size-base-sub
        user-select none

        +layout-xs()
            grid-template-rows min-content auto min-content
            grid-gap .6rem 1.4rem

        &__avatar
            grid-column 1
            grid-row 1 / 3

            +layout-xs()
                grid-row 1

        &__content
            grid-column 2 / 4
            grid-row 2

            +layout-xs()
                grid-column 1 / 4

        &__right-up
            grid-column 3
            grid-row 1
            padding 0
            justify-self flex-end

        &__right-down
            grid-column 3
            grid-row 3
            display flex
            align-items center
            justify-self flex-end

        &__info
            display flex

            +layout-xs()
                margin-top .8rem

        &__user-info
            padding 0
            display flex
            flex-direction row
            align-items center
            word-break break-word
            font-family $font-family-content
            font-weight $font-weight-medium
            font-size 1.2rem
            color $cl-secondary

            +layout-xs()
                flex-direction column
                align-items flex-start

        &__user-name
            margin-right 1rem
            white-space nowrap

        &__user-stat
            white-space nowrap

            &:not(:last-child):after
                content "|"
                margin 0 .8rem
                color $cl-secondary-light

        &__answer
            margin 1rem 0

        &__comment
            margin 0
            font-family $font-family-content
            font-size $font-size-base-sub
            line-height 2.1rem
            white-space pre-wrap
            word-wrap break-word
            color $cl-text-dark

            +layout-xs()
                font-size 1.2rem

        &__parent-comment
            margin-top 1rem
            padding .4rem 1.4rem
            border-left .3rem solid $cl-secondary-light

        &__date
            display flex
            align-items center
            font-size 1.2rem
            color #DADDDE
            white-space nowrap
            background #fff

        &__reply
            button(4, 'text', 'primary')
            padding 0 .8rem
            line-height 1.9rem

        &__remove
            width 3rem
            height @width
            position absolute
            top -(@width / 2)
            right -(@width / 2)
            display flex
            align-items center
            justify-content center
            border-radius 50%
            color #fff
            background-color $cl-secondary
            cursor pointer
            transform scale(0)
            transition .2s transform

            .ui-icon
                width 1.2rem
                height @width

        &:hover &__remove
            transform scale(1)
</style>
