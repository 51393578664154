<template>
    <div class="review-footer">
        <div />

        <template v-if="isMy || isAdmin">
            <div
                v-if="isEdit"
                class="review-footer__controls"
            >
                <button
                    class="review-footer__edit-btn"
                    @click="$emit('cancel')"
                >
                    {{ $t('cancel') }}
                </button>

                <button
                    :disabled="disableSave"
                    class="review-footer__save-btn"
                    @click="$emit('save')"
                >
                    {{ $t('save') }}
                </button>
            </div>

            <button
                v-else
                class="review-footer__btn"
                @click="$emit('edit')"
            >
                {{ $t('edit') }}
            </button>
        </template>

        <div class="review-footer__right-down">
            <button
                :disabled="disableReply"
                class="review-footer__btn"
                @click="$emit('reply')"
            >
                {{ $t('reply') }}
            </button>

            <ReportButton
                v-if="!isMy"
                :id="reviewId"
                object-type="BOOKMAKER_REVIEW"
            />
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "reply": "Ответить",
        "report": "Пожаловаться",
        "edit": "Редактировать",
        "cancel": "Отменить",
        "save": "Сохранить"
    },
    "en": {
        "reply": "Reply",
        "report": "Report",
        "edit": "Edit",
        "cancel": "Cancel",
        "save": "Save"
    }
}
</i18n>

<script>
import { mapActions } from 'vuex'
import ReportButton from '@/components/common/ReportButton/ReportButton.vue'


export default {
    name: 'BookmakersRatingReviewCardFooter',

    components: {
        ReportButton
    },

    props: {
        isAdmin: {
            type: Boolean,
            default: false
        },

        isMy: {
            type: Boolean,
            default: false
        },

        isEdit: {
            type: Boolean,
            default: false
        },

        disableReply: {
            type: Boolean,
            default: false
        },

        disableSave: {
            type: Boolean,
            default: false
        },

        reviewId: {
            type: String,
            required: true
        }
    },

    methods: {
        ...mapActions('report', {
            createReport: 'create'
        }),

        openReportPopup () {
            this.$root.$emit('report', this.report.bind(this))
        },

        report (reason) {
            this.createReport({
                id: this.reviewId,
                type: reason,
                objectType: 'BOOKMAKER_REVIEW'
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.review-footer
    display grid
    grid-template-columns 1fr repeat(2, min-content)
    grid-gap 1rem
    padding 0 2rem 1.8rem
    color #DADDDE
    user-select none

    &__controls
        display grid
        grid-auto-flow column
        grid-gap 1rem

    &__edit-btn
        button(4, 'contained', 'text')

    &__save-btn
        button(4, 'contained', 'success')

    &__btn
        button(4, 'text', 'primary')
        padding 0 .8rem
        line-height 1.9rem

    &__right-down
        display flex
        align-items center
        justify-self flex-end

</style>
