<template>
    <div>
        <UiBreadcrumbs
            :items="breadcrumbs"
            class="view-predictions-item__breadcrumbs"
        />

        <WTeaser :matches="teaser" />

        <BiPageHeader>
            <h1 class="bi-header-4">
                {{ titleText }}
            </h1>
        </BiPageHeader>

        <PredictionsItem
            :key="'prediction' + prediction.id"
            expand
            :prediction="prediction"
        />

        <DFPPromoPrediction />

        <CommentsBlock
            :object-id="prediction.id"
            object-class="BET"
            class="view-predictions-item__comments"
        />

        <PredictionsRecommendationsBlock />

        <WPostRecommendations
            v-if="isRu"
            preset="other"
            class="view-predictions-item__post-recommendations"
        />

        <WBookmakers class="mt-20" />
    </div>
</template>

<i18n>
{
    "ru": {
        "header": "Прогноз на матч",
        "breadcrumbs": "Прогнозы на футбол",
        "breadcrumbs-prediction": "Прогноз %{author} %{predictionId}",
        "title": "Прогноз каппера %{author} на матч \"%{matchName}\" (%{predictionId}) — Betting Insider",
        "description": "Смотреть прогноз каппера %{author} на матч \"%{matchName}\" (%{predictionId}) — Betting Insider",
        "h1": "Прогноз %{author}: Матч %{matchName}",
        "sponsor": "Спонсор прогноза"
    },
    "en": {
        "header": "Match tip",
        "breadcrumbs": "Sports tips",
        "breadcrumbs-prediction": "Tip by %{author} %{predictionId}",
        "title": "Tip by %{author} on match \"%{matchName}\" (%{predictionId}) — Betting Insider",
        "description": "See tip by %{author} on match \"%{matchName}\" (%{predictionId}) — Betting Insider",
        "h1": "Tip by %{author}: Match %{matchName}",
        "sponsor": "Tips sponsored by"
    }
}
</i18n>

<script>
import _get from 'lodash.get'
import { mapActions, mapGetters, mapState } from 'vuex'
import PredictionsItem from '@/components/Predictions/Item/PredictionsItem.vue'
import CommentsBlock from '@/components/common/comments/index.vue'
import BiPageHeader from '@/components/common/bi-page-header/index.vue'
import PredictionsRecommendationsBlock from '@/components/Predictions/Recommendations/PredictionsRecommendationsBlock.vue'
import WBookmakers from '@/components/Widgets/Bookmakers/WBookmakers.vue'
import WPostRecommendations from '@/components/Widgets/PostRecommendations/WPostRecommendations.vue'
import DFPPromoPrediction from '@/components/DFP/DFPPromoPrediction.vue'
import WTeaser from '@/components/Widgets/Teaser/WTeaser.vue'
import { PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID } from '@/constants'
import meta from './meta'


const THRESHOLD_PREDICTION_ID = 11405650

export default {
    name: 'PredictionsItemView',

    components: {
        PredictionsItem,
        BiPageHeader,
        CommentsBlock,
        PredictionsRecommendationsBlock,
        WTeaser,
        WBookmakers,
        WPostRecommendations,
        DFPPromoPrediction
    },

    mixins: [meta],

    async asyncData ({ store, route }) {
        const predictionId = route.params.id

        // 404 если открывают старый прогноз по новому урлу
        if (route.name === 'predictions-item' && predictionId < THRESHOLD_PREDICTION_ID) {
            return Promise.reject({ code: 404 })
        }

        try {
            await store.dispatch('predictions/prediction/fetch', { id: predictionId })
        } catch (err) {
            if (err.code === 301 && err.route) {
                return Promise.reject(err)
            }

            return Promise.reject({ code: 404 })
        }

        if (predictionId > PREDICTION_SMALL_DESCRIPTION_TRESHOLD_ID) {
            store.dispatch('page/setHeader', { name: 'X-Robots-Tag', value: 'noindex' })
        }

        return Promise.all([
            store.dispatch('predictions/recommendations/fetch', {
                exclude: [predictionId]
            }).catch(() => {}),
            WTeaser.loadMatches(store, { hour_range: 48 }),
            CommentsBlock.loadWidget({ store }, {
                objectClass: 'BET',
                objectId: predictionId
            })
        ])
    },

    computed: {
        ...mapGetters('predictions/prediction', ['prediction']),
        ...mapState('auth', ['isAuth']),
        ...mapState('widgets/teaser', ['teaser']),

        date () {
            return this.$date.transform(this.prediction.ctime * 1000, 'date')
        },

        breadcrumbs () {
            return [
                {
                    title: 'Betting Insider',
                    to: {
                        name: 'home'
                    }
                },
                {
                    title: this.$t('breadcrumbs'),
                    to: {
                        name: 'predictions',
                        params: {
                            sport: 'football'
                        }
                    }
                },
                {
                    title: this.tournament.name,
                    to: {
                        name: 'tournament',
                        params: {
                            alias: this.tournament.webname,
                            sport: 'football'
                        }
                    }
                },
                {
                    title: this.matchName,
                    to: {
                        name: 'match',
                        params: {
                            id: this.prediction.match.id
                        }
                    }
                },
                {
                    title: this.breadcrumbsPrediction
                }
            ]
        },

        titleText () {
            return this.$t('h1', {
                matchName: this.matchName,
                author: this.predictionAuthor
            })
        },

        image () {
            const { locale } = this.$i18n
            const predictionId = this.$route.params.id

            return `https://betting.team/imaginarium/images/bettinginsider/${locale}/prediction/${predictionId}.jpeg`
        },

        matchName () {
            const homeTeamName = _get(this.prediction, 'match.homeTeam.team.name', '')
            const awayTeamName = _get(this.prediction, 'match.awayTeam.team.name', '')

            return `${homeTeamName} — ${awayTeamName}`
        },

        predictionAuthor () {
            return _get(this.prediction, 'user.name', '')
        },

        tournament () {
            return _get(this.prediction, 'match.tournament', '')
        },

        breadcrumbsPrediction () {
            return this.$t('breadcrumbs-prediction', {
                matchName: this.matchName,
                author: this.predictionAuthor
            })
        },

        isRu () {
            return this.$i18n.locale === 'ru'
        }
    },

    watch: {
        isAuth (newVal) {
            if (newVal) {
                this.reload()
            }
        }
    },

    created () {
        const predictionId = this.$route.params.id

        // Редирект если открывают новый прогноз по старому урлу
        if (this.$route.name === 'predictions-item-old' && predictionId > THRESHOLD_PREDICTION_ID) {
            this.$router.push({ path: this.prediction.path })
        }
    },

    methods: {
        ...mapActions({
            fetchPredictions: 'predictions/prediction/fetch'
        }),

        reload () {
            const predictionId = this.$route.params.id

            this.fetchPredictions({ id: predictionId })
            CommentsBlock.loadWidget({ store: this.$store }, {
                objectClass: 'BET',
                objectId: predictionId
            })
        }
    }
}
</script>

<style lang="stylus" scoped>
.view-predictions-item
    &__breadcrumbs
        margin-bottom .8rem

        +layout-sm-and-up()
            margin-bottom 1.6rem

    &__comments
        padding 1rem 0

    &__post-recommendations
        margin 4rem 0 3rem
</style>
