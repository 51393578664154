import { render, staticRenderFns } from "./BookmakersRatingReviewCardFooter.vue?vue&type=template&id=e7abfa78&scoped=true&"
import script from "./BookmakersRatingReviewCardFooter.vue?vue&type=script&lang=js&"
export * from "./BookmakersRatingReviewCardFooter.vue?vue&type=script&lang=js&"
import style0 from "./BookmakersRatingReviewCardFooter.vue?vue&type=style&index=0&id=e7abfa78&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e7abfa78",
  null
  
)

/* custom blocks */
import block0 from "./BookmakersRatingReviewCardFooter.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports