<template>
    <div class="bi-page-header bi-layout">
        <div class="bi-page-header__main">
            <slot />
        </div>

        <div
            v-if="!!$slots['aside']"
            class="bi-page-header__aside"
        >
            <slot name="aside" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BiPageHeader'
}
</script>

<style lang="stylus">
    .bi-page-header
        padding 1.2rem 0 2rem 0

        +layout-xs()
            padding .8rem 0 1.4rem 0

        &__main
            flex 1 1

            h1, h2
                margin-bottom 0

        &__aside
            margin-top 1.2rem
            flex 1 1 100%
            align-self center

            +layout-sm-and-up()
                padding-top 0
                margin-top 0
                border-top 0 none
                flex none
</style>
