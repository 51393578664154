<template>
    <div>
        <BookmakerRatingCommentCard
            :comment-id="id"
            :date="ctime"
            :is-my="isMy"
            :user="user"
            :comment="comment"
            :parent-comment="parentComment"
            :disable-reply="showForm"
            @remove="remove"
            @reply="reply"
        />

        <BookmakerRatingCommentForm
            v-if="showForm"
            :placeholder="placeholder"
            :quote="quote"
            class="comments-form"
            @submit="send"
            @close="reply"
        />
    </div>
</template>

<i18n>
{
    "ru": {
        "placeholder": "Ответ к комментарию %{name}"
    },
    "en": {
        "placeholder": "Reply to %{name} comment"
    }
}
</i18n>

<script>
import BookmakerRatingCommentForm from '@/components/BookmakersRating/Comments/BookmakerRatingCommentForm.vue'
import BookmakerRatingCommentCard from '@/components/BookmakersRating/Comments/BookmakerRatingCommentCard.vue'

export default {
    name: 'BookmakerRatingComment',

    components: {
        BookmakerRatingCommentForm,
        BookmakerRatingCommentCard
    },

    props: {
        id: {
            type: String,
            default: ''
        },

        ctime: {
            type: Number,
            default: 0
        },

        isMy: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        comment: {
            type: String,
            default: ''
        },

        parentComment: {
            type: Object,
            default: () => ({})
        },

        showForm: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        placeholder () {
            return this.$t('placeholder', {
                name: this.user.name
            })
        },

        quote () {
            return {
                userName: this.user.name,
                comment: this.comment
            }
        }
    },

    methods: {
        send (text) {
            this.$emit('send', {
                comment: text,
                parentId: this.id
            })
        },

        reply (id = '') {
            this.$emit('reply', id)
        },

        remove (commentId) {
            this.$emit('remove', commentId)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .comments-form
        margin-top .8rem
</style>
