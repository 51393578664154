<template>
    <button
        class="mark-button"
        :disabled="disabled"
        :class="{ 'mark-button--dirty': dirty }"
        @click="$emit('click', $event)"
    >
        <slot />
    </button>
</template>

<script>
export default {
    name: 'BookmakersRatingMyReviewButton',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },

        dirty: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="stylus" scoped>
.mark-button
    button(5, 'contained', 'success')

    &--dirty
        background linear-gradient(90deg, $cl-success 0%, $cl-success 50%, lighten($cl-success, 7%) 50%, lighten($cl-success, 7%) 100%)

</style>

