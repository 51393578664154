<template>
    <div class="bi-block">
        <BookmakersRatingReviewCardHeader
            :is-my="isMy"
            :is-edit="isEdit"
            :user="user"
            :ctime="ctime"
        />

        <BookmakersRatingReviewCardContent
            :is-edit="isEdit"
            :user="user"
            :score="score / 20"
            :description="description"
            @input="input"
            @change-rating="changeRating"
        />

        <BookmakersRatingReviewCardFooter
            :is-my="isMy"
            :is-admin="isAdminRole"
            :is-edit="isEdit"
            :disable-save="!isReadyToSave"
            :disable-reply="disableReply"
            :review-id="id"
            @save="save"
            @edit="edit"
            @cancel="cancel"
            @reply="reply"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BookmakersRatingReviewCardHeader from '@/components/BookmakersRating/Review/BookmakersRatingReviewCardHeader.vue'
import BookmakersRatingReviewCardContent from '@/components/BookmakersRating/Review/BookmakersRatingReviewCardContent.vue'
import BookmakersRatingReviewCardFooter from '@/components/BookmakersRating/Review/BookmakersRatingReviewCardFooter.vue'

export default {
    name: 'BookmakersRatingReviewCard',

    components: {
        BookmakersRatingReviewCardHeader,
        BookmakersRatingReviewCardContent,
        BookmakersRatingReviewCardFooter
    },

    props: {
        id: {
            type: String,
            default: ''
        },

        ctime: {
            type: Number,
            default: 0
        },

        isMy: {
            type: Boolean,
            default: false
        },

        disableReply: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        score: {
            type: Number,
            default: 0
        },

        description: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            isEdit: false,
            isDirty: false,
            rating: null,
            text: ''
        }
    },

    computed: {
        ...mapGetters('profile', ['isAdmin']),

        isReadyToSave () {
            return (this.isDirty && this.text) || (this.rating && (this.rating !== this.score))
        },

        isAdminRole () {
            return this.isAdmin
        }
    },

    methods: {
        ...mapActions({
            updateReview: 'bookmakersRating/updateReview'
        }),

        input (value) {
            this.text = value

            if (!this.isDirty) {
                this.isDirty = true
            }
        },

        changeRating (value) {
            this.rating = value
        },

        save () {
            if (this.isReadyToSave) {
                this.$emit('save')

                if (this.isAdminRole) {
                    this.$pivik.event(
                        'bookmaker_rating',
                        'admin_actions',
                        'save_edit_review'
                    )
                }

                this.updateReview({
                    id: this.id,
                    score: this.rating,
                    description: this.text
                })
                    .then(() => {
                        this.isEdit = false
                        this.rating = null
                        this.text = ''
                    })
            }
        },

        cancel () {
            if (this.isAdminRole) {
                this.$pivik.event(
                    'bookmaker_rating',
                    'admin_actions',
                    'cancel_edit_review'
                )
            }

            this.isEdit = false
            this.rating = null
            this.text = ''
        },

        edit () {
            if (this.isAdminRole) {
                this.$pivik.event(
                    'bookmaker_rating',
                    'admin_actions',
                    'click_edit_review'
                )
            }

            this.$emit('edit')
            this.isEdit = true
        },

        reply () {
            this.$emit('reply', this.id)
        }
    }
}
</script>
