export default {
    head () {
        const head = {
            title: this.title,
            meta: [
                { id: 'description', name: 'description', content: this.description },
                { id: 'og:title', property: 'og:title', content: this.title },
                { id: 'og:description', property: 'og:description', content: this.description },
                { id: 'og:image', property: 'og:image', content: this.image }
            ],
            link: [
                { id: 'canonical', rel: 'canonical', href: this.canonical },
                { id: 'image_src', rel: 'image_src', href: this.image }
            ]
        }

        if (this.isShortComment) {
            head.meta.push({ name: 'robots', content: 'noindex' })
        }

        if (this.isRuComment) {
            head.link.push(
                { id: 'hreflang-en' },
                { id: 'hreflang-ru', rel: 'alternate', href: this.canonicalRu, hreflang: 'ru' },
                { id: 'hreflang-ru-RU', rel: 'alternate', href: this.canonicalRu, hreflang: 'ru-RU' },
                { id: 'hreflang-ru-BY', rel: 'alternate', href: this.canonicalRu, hreflang: 'ru-BY' },
                { id: 'hreflang-ru-UA', rel: 'alternate', href: this.canonicalRu, hreflang: 'ru-UA' },
                { id: 'hreflang-ru-KZ', rel: 'alternate', href: this.canonicalRu, hreflang: 'ru-KZ' }
            )
        } else if (this.isEnComment) {
            head.link.push(
                { id: 'hreflang-en', rel: 'alternate', href: this.canonicalEn, hreflang: 'en' },
                { id: 'hreflang-ru' },
                { id: 'hreflang-ru-RU' },
                { id: 'hreflang-ru-BY' },
                { id: 'hreflang-ru-UA' },
                { id: 'hreflang-ru-KZ' }
            )
        } else {
            head.link.push(
                { id: 'hreflang-en', rel: 'alternate', href: this.canonicalEn, hreflang: 'en' },
                { id: 'hreflang-ru' },
                { id: 'hreflang-ru-RU' },
                { id: 'hreflang-ru-BY' },
                { id: 'hreflang-ru-UA' },
                { id: 'hreflang-ru-KZ' }
            )
        }

        return head
    },

    computed: {
        commonTranslationData () {
            return {
                matchName: this.matchName,
                date: this.date,
                author: this.predictionAuthor,
                predictionName: this.prediction.fullName,
                predictionId: this.prediction.id
            }
        },

        title () {
            return this.$t('title', this.commonTranslationData)
        },

        canonical () {
            let { path } = this.$route

            if (!this.prediction.description || this.prediction.description.length < 20) {
                const to = {
                    name: 'match',
                    params: {
                        id: this.prediction.match.id
                    }
                }
                const route = this.$router.resolve(to, this.$route)

                path = route.href
            }

            return this.$canonical.get(path)
        },

        canonicalRu () {
            return this.$canonical.get(this.$route.path, 'ru')
        },

        canonicalEn () {
            return this.$canonical.get(this.$route.path, 'en')
        },

        description () {
            return this.$t('description', this.commonTranslationData)
        },

        isRuComment () {
            return this.prediction.description && this.prediction.description.search(/[а-я]/i) > -1
        },

        isEnComment () {
            return this.prediction.description && this.prediction.description.search(/[a-z]/i) > -1
        },

        isShortComment () {
            return !this.prediction.description || this.prediction.description.length < 100
        }
    }
}
