<template>
    <div class="imaginarium-layout">
        <div class="imaginarium-layout__wrap">
            <div class="imaginarium-layout__print" />

            <div class="imaginarium-layout__body">
                <slot />
            </div>

            <div
                v-if="hasFooterSlot"
                class="imaginarium-layout__footer"
            >
                <IconLogo class="layout-imaginarium__footer-logo" />
                <span
                    v-if="footerSiteName"
                    class="imaginarium-layout__footer-site-name"
                >Betting Insider</span>

                <span class="imaginarium-layout__footer-sep" />

                <div class="imaginarium-layout__footer-content">
                    <slot name="footer" />
                </div>

                <div class="imaginarium-layout__footer-versions">
                    <IconApple />
                    <IconAndroid />
                    <IconDesktop />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconApple from '@/assets/svg/apple.icon.svg'
import IconAndroid from '@/assets/svg/android.icon.svg'
import IconDesktop from '@/assets/svg/desktop.icon.svg'

export default {
    name: 'I9mLayout',

    components: {
        IconApple,
        IconAndroid,
        IconDesktop
    },

    props: {
        footerSiteName: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasFooterSlot () {
            return !!this.$slots.footer
        }
    }
}
</script>

<style lang="stylus" scoped>
    .imaginarium-layout
        min-height 100vh
        text-align center
        background-color $cl-text-dark

        &__wrap
            position relative
            box-sizing border-box
            display inline-block
            padding 15px 23px 0 23px
            width 100%
            max-width 1150px
            text-align left

            &::before,
            &::after
                content ""
                position absolute
                top 0
                display block
                width 50px
                height 100%
                background-repeat no-repeat

            &::before
                left -27px
                background-image url('@/assets/images/imaginarium/bg-left.png')

            &::after
                right -27px
                background-image url('@/assets/images/imaginarium/bg-right.png')

        &__print
            position absolute
            z-index 1
            top 0
            left 0
            right 0
            height 37px
            background-color #000

        &__body
            position relative
            z-index 10
            margin-bottom 55px
            padding 25px
            min-height 410px
            background-color #fff

            &::before
                content ""
                display block
                position absolute
                top 0
                left 0
                right 0
                height 24px
                background url("@/assets/images/imaginarium/bg-top-shadow.png") repeat-x
                background-position top left

            &::after
                content ""
                height 26px
                display block
                position absolute
                top 100%
                left 0
                right 0
                background url("@/assets/images/imaginarium/body-bg-footer.png") repeat-x
                background-position top left

        &__footer
            color #fff
            display flex
            font-size 3.4rem
            font-weight $font-weight-bold

            &-logo
                height 55px
                width 55px
                margin 0 3px

            &-sep
                display inline-block
                margin 0 35px
                width 1px
                height 55px
                background-color #888b91

            &-site-name
                margin-left 20px
                line-height 55px
                text-transform uppercase
                vertical-align top

            &-content
                flex 1
                display block
                min-height 55px
                line-height 55px
                align-items center

            &-versions
                display block
                min-height 55px

                .ui-icon
                    display inline-block
                    margin-top 2px
                    margin-left 30px
                    height 40px
                    width 40px
                    vertical-align top
</style>
