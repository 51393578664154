<template>
    <div
        class="interscroller"
    >
        <RefreshableDfp
            :overflow="true"
            :placement="placement"
            @receive="showCreative"
            @render="() => (isRendered = true)"
        />
        <div
            v-show="!creative.show"
            class="interscroller__legacy-container"
        >
            <div>
                <div
                    :id="id"
                    class="interscroller__ad"
                />
            </div>
        </div>
        <div
            v-if="creative.show"
            class="interscroller__creative"
        >
            <div class="interscroller__outer-wrapper">
                <div
                    v-if="creative.disclaimer"
                    class="interscroller__disclaimer-wrapper"
                >
                    <AdDisclaimer
                        v-if="creative.disclaimer"
                        :text="creative.disclaimer"
                    />
                </div>

                <a
                    :href="creative.link"
                    class="interscroller__link"
                    target="_blank"
                    @click="creativeClickHandler"
                >

                    <img
                        :src="creative.image"
                        :alt="creative.bookmaker"
                        class="interscroller__image"
                    >
                    <img
                        v-if="creative.trackView"
                        :src="creative.trackView"
                        class="interscroller__track-pixel"
                    >
                    <img
                        v-if="creative.trackViewCustom"
                        :src="creative.trackViewCustom"
                        class="interscroller__track-pixel interscroller__track-pixel--custom"
                    >
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import dfp from '@/mixins/dfp'
import AdDisclaimer from '@/components/DFP/AdDisclaimer.vue'
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'

export default {
    name: 'DFPInterscroller',

    components: {
        AdDisclaimer,
        RefreshableDfp
    },

    mixins: [dfp],

    props: {
        placement: {
            type: String,
            default: ''
        }

    },

    data () {
        return {
            creative: {
                show: false
            }
        }
    },

    mounted () {
        this.init()
        this.$on('receive', this.showCreative)
    },

    methods: {
        showCreative (message) {
            if (message) {
                this.creative.link = message.link
                this.creative.trackView = message.trackView
                this.creative.trackViewCustom = message.trackViewCustom
                this.creative.image = message.imageSrc
                this.creative.bookmaker = message.bookmakerName || 'unknown'
                this.creative.disclaimer = message.disclaimer
                this.creative.show = true
                this.$pivik.event('dfp_show', this.placement, this.creative.bookmaker)
            }
        },

        creativeClickHandler () {
            this.$pivik.event('dfp_click', this.placement, this.creative.bookmaker)
        }
    }
}
</script>

<style rel="stylesheet/stylus" lang="stylus">
.interscroller
    height 30rem
    box-sizing content-box
    position relative

    &__outer-wrapper
        position relative
        margin 2em auto
        width 100vw
        height 100vh
        clip rect(0, auto, auto, 0)
        clip-path inset(0 0)
        user-select none

    &__creative
        position absolute
        width 100%
        height 30rem
        clip rect(0, 100vw, 30rem, 0)
        clip-path inset(0 0)

    &__image
        position fixed
        top 50%
        left 50%
        backface-visibility hidden
        transform translate3d(-50%, -50%, 0)
        user-select none

    &__disclaimer-wrapper
        position absolute
        left 1rem
        top 1rem
        border-radius $radius-block
        background rgba(255, 255, 255, .95)
        width 3.6rem
        height 3.6rem
        padding .6rem
        box-sizing border-box
        z-index 2
        font-size 0

    &__track-pixel
        display none

    // обратная совместимость со старыми баннерами
    &__legacy-container
        position absolute
        width 100%
        height 30rem
        clip rect(0, 100vw, 30rem, 0)
        clip-path inset(0 0)

        div
            user-select none

</style>
