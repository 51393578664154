<template>
    <div>
        <BookmakersRatingReviewCard
            :id="id"
            :ctime="ctime"
            :is-my="isMy"
            :user="user"
            :score="score"
            :description="description"
            :disable-reply="showForm"
            class="rb__review"
            @reply="reply"
            @save="$emit('save')"
            @edit="$emit('edit')"
        />

        <BookmakerRatingCommentForm
            v-if="showForm"
            :placeholder="placeholder"
            class="review-comment-form"
            @submit="send"
            @close="reply"
        />
    </div>
</template>

<i18n>
    {
        "ru": {
            "placeholder": "Ответ к отзыву %{name}"
        },
        "en": {
            "placeholder": "Reply to %{name} review"
        }
    }
</i18n>

<script>
import BookmakersRatingReviewCard from '@/components/BookmakersRating/Review/BookmakersRatingReviewCard.vue'
import BookmakerRatingCommentForm from '@/components/BookmakersRating/Comments/BookmakerRatingCommentForm.vue'

export default {
    name: 'BookmakersRatingReview',

    components: {
        BookmakersRatingReviewCard,
        BookmakerRatingCommentForm
    },

    props: {
        showForm: {
            type: Boolean,
            default: false
        },

        id: {
            type: String,
            default: ''
        },

        ctime: {
            type: Number,
            default: 0
        },

        isMy: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        score: {
            type: Number,
            default: 0
        },

        description: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            isEdit: false,
            isDirty: false,
            rating: null,
            text: ''
        }
    },

    computed: {
        placeholder () {
            return this.$t('placeholder', {
                name: this.user.name
            })
        }
    },

    methods: {
        send (text) {
            this.$emit('send', {
                id: this.id,
                comment: text,
                type: 'review'
            })
        },

        reply (id = '') {
            this.$emit('reply', id)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .review-comment-form
        margin-top .8rem

</style>
