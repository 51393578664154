<template>
    <section
        class="promo bi-block"
    >
        <RefreshableDfp
            placement="prediction_feed_native"
            overflow
            @receive="receiveMessage"
        />
        <a
            v-show="link && isVisible"
            rel="nofollow"
            target="_blank"
            :href="link"
        >
            <div
                :style="titleStyles"
                class="promo__title"
            >
                {{ text }}
            </div>

            <UiComment
                :style="commentStyles"
                class="promo__comment"
            >
                {{ secondaryText }}
            </UiComment>

            <div class="promo__footer">
                <UiImage
                    :src="image"
                    class="promo__image"
                    reload-on-image-change
                />

                <div class="promo__footer-separator" />

                <button
                    :style="buttonStyles"
                    class="promo__button"
                >
                    {{ buttonTitle }}
                </button>
            </div>
            <span
                class="bi-subtitle-3"
            >
                {{ marker }}
            </span>
        </a>
    </section>
</template>

<script>
import RefreshableDfp from '@/components/DFP/RefreshableDfp.vue'
import adaptColor from '@/utils/adapt-color'

export default {
    name: 'DFPPromoPrediction',

    components: {
        RefreshableDfp
    },

    data () {
        return {
            isVisible: false,
            link: '',
            image: '',
            text: '',
            marker: '',
            textColor: '',
            secondaryText: '',
            buttonBackgroundColor: '',
            buttonTitle: '',
            buttonTitleColor: '',
            secondaryTextColor: ''
        }
    },

    computed: {
        titleStyles () {
            return {
                color: this.textColor
            }
        },

        commentStyles () {
            return {
                color: this.secondaryTextColor
            }
        },

        buttonStyles () {
            return {
                color: this.buttonTitleColor,
                backgroundColor: this.buttonBackgroundColor
            }
        }
    },

    methods: {
        receiveMessage (data) {
            if (data.link && data.image && data.text && data.buttonTitle) {
                this.isVisible = true
                this.link = data.link
                this.image = data.image
                this.text = data.text
                this.textColor = adaptColor(data.textColor)
                this.secondaryText = data.secondaryText
                this.secondaryTextColor = adaptColor(data.secondaryTextColor)
                this.buttonBackgroundColor = adaptColor(data.buttonBackgroundColor)
                this.buttonTitle = data.buttonTitle
                this.marker = data.marker
                this.buttonTitleColor = adaptColor(data.buttonTitleColor)
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
.promo
    display flex
    flex-direction column
    padding 1.7rem 2rem 1.6rem 1.6rem
    margin-top 1rem

    &:hover, &:active, &:focus
        text-decoration none
        outline none

    &__title
        margin-left .5rem
        margin-bottom 1.8rem
        font-family $font-family
        font-weight $font-weight-bold
        font-size 2.4rem
        line-height 2.8rem

    &__comment
        margin-bottom 1.7rem

        +layout-xxs()
            margin-bottom 2.6rem

    &__footer
        display flex
        align-items center

        +layout-xxs()
            flex-direction column

        &-separator
            width .2rem
            height 4rem
            margin 0 1.9rem 0 2.1rem
            background-color $cl-secondary-light

            +layout-xxs()
                display none

    &__image
        width 12rem
        height 4rem

        +layout-xxs()
            margin-bottom .8rem

    &__button
        button(5, 'contained')
        min-width 20rem

        +layout-xxs()
            width 100%

>>> .ui-comment__message
    line-height 2rem

</style>
