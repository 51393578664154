<template>
    <div class="review-content">
        <BiUserAvatar
            :src="user.avatar"
            class="review-content__avatar"
        />

        <template v-if="isEdit">
            <div class="review-content__score">
                <div class="review-content__my-rating">
                    <UiStarRating
                        :rating="score"
                        :star-size="16"
                        :increment="1"
                        :padding="5"
                        border-color="#F6C247"
                        :border-width="1"
                        active-color="#F6C247"
                        inactive-color="#FFF"
                        @rating-selected="ratingSelected"
                    />
                    <span>{{ score.toFixed(1) }}</span>
                </div>
            </div>
            <textarea
                :value="description"
                :placeholder="$t('placeholder')"
                class="review-content__description review-content__textarea"
                @input="$emit('input', $event.target.value)"
            />
        </template>
        <template v-else>
            <UiComment class="review-content__score">
                <div class="review-content__rating">
                    <UiStarRating
                        read-only
                        :rating="score"
                        :star-size="16"
                        :increment="1"
                        :padding="5"
                        border-color="#F6C247"
                        :border-width="1"
                        active-color="#F6C247"
                        inactive-color="#EFEFEF"
                    />
                    <span>{{ score.toFixed(1) }}</span>
                </div>
            </UiComment>

            <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
            <div class="review-content__description">{{ description }}</div>
        </template>
    </div>
</template>

<i18n>
{
    "ru": {
        "placeholder": "Напишите свой отзыв"
    },
    "en": {
        "placeholder": "Write your review"
    }
}
</i18n>

<script>
import BiUserAvatar from '@/components/common/bi-user-avatar/index.vue'

export default {
    name: 'BookmakersRatingReviewCardContent',

    components: {
        BiUserAvatar
    },

    props: {
        isEdit: {
            type: Boolean,
            default: false
        },

        user: {
            type: Object,
            default: () => ({})
        },

        score: {
            type: Number,
            default: 0
        },

        description: {
            type: String,
            default: ''
        }
    },

    methods: {
        ratingSelected (val) {
            this.$emit('change-rating', val * 20)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .review-content
        padding 1.5rem 2rem
        display grid
        grid-template-columns min-content auto
        grid-template-rows 1fr
        grid-gap 1.4rem
        align-items center

        +layout-xs()
            padding 1.5rem

        &__avatar
            grid-column 1

        &__score
            width fit-content
            grid-row 1
            grid-column 2

        &__rating
            display flex
            align-items center

            & > span
                margin-left .9rem
                white-space nowrap
                font-family $font-family-content
                font-weight $font-weight-bold
                font-size 1.6rem
                color rgba(57, 62, 73, .3)

        &__description
            grid-row 2
            grid-column 1 / 3

            color $cl-text-dark
            font-family $font-family-content
            font-size 1.4rem
            letter-spacing 0
            line-height 1.8rem
            white-space break-spaces
            word-break break-all


        &__my-rating
            padding 1.4rem 1.5rem 1.4rem 2.4rem
            display flex
            align-items center
            box-shadow inset 0 1px 5px rgba(0, 0, 0, .1)
            border-radius 21px

            & > span
                margin-left .9rem
                white-space nowrap
                font-family $font-family-content
                font-weight $font-weight-bold
                font-size 1.6rem
                color rgba(57, 62, 73, .3)

        &__textarea
            padding 1rem
            min-height 14rem
            border-radius 4px
            background #F9F9F9
            border 1px solid @background
            box-shadow inset 0 1px 2px 0 rgba(0, 0, 0, .1)
            resize vertical
            outline none
</style>
