<template>
    <div class="comments">
        <BiInfiniteList
            v-if="hasComments"
            :is-full="isAll"
            :auto-load-count="0"
            :load-more="loadMoreComments"
            :button-text="$t('more')"
            light-theme
            class="comments__list"
        >
            <BookmakerRatingComment
                v-for="comment in commentsList"
                :id="comment.id"
                :key="comment.id"
                :ctime="comment.ctime"
                :is-my="comment.user.id === myProfile.id"
                :user="comment.user"
                :comment="comment.text"
                :parent-comment="comment.parentComment"
                :show-form="comment.id === answerId"
                class="comments__item"
                @reply="reply"
                @send="send"
                @remove="onRemove"
            />
        </BiInfiniteList>
    </div>
</template>

<i18n>
{
    "ru": {
        "placeholder": "Ответ к комментарию %{name}",
        "comments": "Комментарии",
        "more": "Показать еще"
    },
    "en": {
        "placeholder": "Reply to %{name} comment",
        "comments": "Comments",
        "more": "More"
    }
}
</i18n>

<script>
import { mapGetters, mapActions } from 'vuex'
import BiInfiniteList from '@/components/common/bi-infinite-list/index.vue'
import BookmakerRatingComment from '@/components/BookmakersRating/Comments/BookmakerRatingComment.vue'
import ErrorMessage from '@/mixins/error-message'

export default {
    name: 'BookmakerRatingCommentsList',

    components: {
        BiInfiniteList,
        BookmakerRatingComment
    },

    mixins: [ErrorMessage],

    props: {
        id: {
            type: String,
            default: ''
        },

        answerId: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            message: '',
            pending: false,
            errorMessage: '',
            parentComment: {}
        }
    },

    computed: {
        ...mapGetters({
            comments: 'bookmakersRating/comments/comments',
            isAllComments: 'bookmakersRating/comments/isAllComments',
            myProfile: 'profile/my'
        }),

        isAll () {
            return this.isAllComments(this.id)
        },

        commentsList () {
            return this.comments(this.id)
        },

        hasComments () {
            return !!this.comments(this.id).length
        }
    },

    methods: {
        ...mapActions({
            commentsFetch: 'bookmakersRating/comments/fetch',
            commentRemove: 'bookmakersRating/comments/remove',
            commentReport: 'comments/report'
        }),

        reply (id = '') {
            this.$emit('reply', id)
        },

        send ({ parentId, comment }) {
            this.$emit('send', {
                id: this.id,
                comment,
                parentId,
                type: 'comment'
            })
        },

        loadMoreComments () {
            if (this.isAll) {
                return Promise.reject()
            }

            this.$emit('close-reply')
            this.$emit('event-load', this.id)

            return this.commentsFetch({ id: this.id })
        },

        onRemove (commentId) {
            this.$pivik.event(
                'bookmaker_rating',
                'admin_actions',
                'delete_comment'
            )

            this.commentRemove({
                id: this.id,
                commentId
            })
        },

        report (id) {
            this.commentReport(id)
        }
    }
}
</script>

<style lang="stylus" scoped>
    .comments
        margin-top .8rem

        &__header
            padding 3rem 0
            display grid
            grid-template-columns 1fr auto
            grid-template-rows auto auto
            grid-gap 1.8rem 0

            +layout-xs()
                grid-template-rows auto auto auto

        &__header-text
            font-size 2.4rem
            line-height 2.9rem
            text-transform uppercase

            +layout-xs()
                padding-left .6rem

        &__switch
            +layout-xs()
                grid-row 3
                padding-left .6rem

        &__form
            grid-column 1 / 3

        &__list
            max-width 81rem
            margin .8rem 0 0 auto
</style>
