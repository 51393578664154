<template>
    <div class="mark">
        <div class="mark__score">
            <h2 class="mark__title">
                {{ $t('score_avg') }}
            </h2>

            <div class="mark__rating">
                <span v-if="rating">{{ ratingScore }}</span>
                <span
                    v-else
                    class="mark__rating--default"
                >5.0</span>

                <UiStarRating
                    type="text"
                    :read-only="!isEdit"
                    :rating="rating / 20"
                    :star-size="22"
                    :increment="1"
                    :padding="5"
                    border-color="#F6C247"
                    :border-width="2"
                    active-color="#F6C247"
                    inactive-color="#FFF"
                    @rating-selected="ratingSelected"
                />
            </div>
        </div>

        <!-- eslint-disable vue/no-v-html -->
        <div
            v-if="id && !isEdit"
            class="mark__text"
            v-html="text"
        />
        <!-- eslint-enable vue/no-v-html -->
        <textarea
            v-else
            v-model="text"
            :placeholder="$t('placeholder')"
            class="mark__text mark__textarea"
            :class="{ 'mark__textarea--warning': showWarning && !text }"
        />

        <div class="mark__controls">
            <span
                v-if="showWarning"
                class="mark__warning"
            >{{ warning }}</span>

            <BookmakersRatingMyReviewButton
                v-if="id && !isEdit"
                @click="edit"
            >
                {{ $t('update') }}
            </BookmakersRatingMyReviewButton>
            <template v-else>
                <BookmakersRatingMyReviewButton
                    :dirty="isDirty"
                    :disabled="isEmpty || isUnchanged"
                    @click="send"
                >
                    {{ id ? $t('save') : $t('add') }}
                </BookmakersRatingMyReviewButton>
                <button
                    v-if="id"
                    class="mark__cancel-button"
                    @click="cancel"
                >
                    {{ $t('cancel') }}
                </button>
            </template>
        </div>
    </div>
</template>

<i18n>
{
    "ru": {
        "add": "Добавить отзыв",
        "update": "Обновить отзыв",
        "save": "Сохранить изменения",
        "cancel": "Отменить изменения",
        "score_avg": "Ваша оценка",
        "placeholder": "Напишите свой отзыв",
        "warning_score": "Не забудьте поставить оценку",
        "warning_mark": "Не забудьте написать отзыв"
    },
    "en": {
        "add": "Add a review",
        "update": "Edit review",
        "save": "Save review",
        "cancel": "Сancel",
        "score_avg": "Your rating",
        "placeholder": "Write your review",
        "warning_score": "Don’t forget to add rating",
        "warning_mark": "Don’t forget to write review"
    }
}
</i18n>

<script>
import BookmakersRatingMyReviewButton from '@/components/BookmakersRating/MyReview/BookmakersRatingMyReviewButton.vue'

export default {
    name: 'BookmakersRatingMyReview',

    components: {
        BookmakersRatingMyReviewButton
    },

    props: {
        id: {
            type: String,
            default: ''
        },

        score: {
            type: Number,
            default: null
        },

        description: {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            showWarning: false,
            isEdit: false,
            rating: 0,
            text: ''
        }
    },

    computed: {
        isEmpty () {
            return !this.rating && !this.text
        },

        isDirty () {
            return !((this.isEmpty) || (this.rating && this.text))
        },

        isUnchanged () {
            return (this.rating === this.score) && (this.text === this.description)
        },

        isReady () {
            return !(this.isDirty || this.isEmpty)
        },

        warning () {
            if (this.isDirty) {
                return this.rating ? this.$t('warning_mark') : this.$t('warning_score')
            }

            return ''
        },

        ratingScore () {
            const val = this.rating / 20
            const fixStr = Number.isNaN(val) ? 0 : parseFloat(val)

            return fixStr.toFixed(1)
        }
    },

    watch: {
        id () {
            this.isEdit = !this.id
        },

        score () {
            this.rating = this.score
        },

        description () {
            this.text = this.description
        },

        rating () {
            if (this.showWarning) {
                this.showWarning = false
            }
        },

        text () {
            if (this.showWarning) {
                this.showWarning = false
            }
        }
    },

    mounted () {
        this.isEdit = !this.id
        this.rating = this.score
        this.text = this.description
    },

    methods: {
        ratingSelected (val) {
            this.rating = val * 20
        },

        send () {
            if (this.isReady) {
                this.$emit('send', {
                    id: this.id,
                    score: this.rating,
                    description: this.text
                })

                this.isEdit = false
            } else if (this.isDirty) {
                this.showWarning = true
            }
        },

        edit () {
            this.$emit('edit')
            this.isEdit = true
        },

        cancel () {
            this.isEdit = false
            this.rating = this.score
            this.text = this.description
        }
    }
}
</script>

<style lang="stylus" scoped>
    .mark
        padding 1.8rem
        display grid
        grid-template-columns 1fr 24rem
        grid-template-rows 1fr min-content
        grid-gap 1.6rem

        border-radius 8px
        color $cl-text-dark
        font-family $font-family
        border .1rem solid $cl-secondary-light
        user-select none

        +layout-sm()
            grid-template-columns 1fr
            grid-template-rows 1fr auto min-content
            padding 1.2rem 1.8rem

        &__score
            grid-column 2

            +layout-sm()
                grid-column 1

        &__text
            grid-column 1
            grid-row 1 / 3
            input()

            &:not(input)
                min-height 17rem

        &__textarea
            resize vertical

            +layout-sm()
                grid-row initial

            &--warning
                border 1px solid $cl-error

        &__controls
            grid-column 2

            display grid
            grid-gap .6rem
            align-items flex-end

            +layout-sm()
                grid-column 1

        &__input
            height 100%

        &__title
            margin 0
            font-size 2rem
            font-family $font-family
            font-weight $font-weight-regular
            text-transform uppercase
            letter-spacing .5px

        &__rating
            display flex
            flex-direction row
            align-items center

            &--default
                color #E9E9E9

            span
                margin-right 1.5rem
                font-size 4.6rem
                font-family $font-family-content
                font-weight $font-weight-bold

        &__warning
            width 100%
            font-family $font-family-content
            font-size 1.2rem
            color $cl-error
            letter-spacing 0
            line-height 1.8rem

            +layout-sm()
                grid-row 2
                text-align center

        &__cancel-button
            button(5, 'contained', 'text')
</style>
